<template>
    <div>
        <p class="ml-1" style="font-weight: 600; font-size: 20px; margin-bottom: 16px;">{{$t('orders.title')}}</p>

        <template v-for="(item, index) in orders">
            <b-card  @click="toggleCollapse(item)" :bg-variant=" item.collapsed ? 'light' : ''" class="mb-0" no-body style="padding: 16px;">

                <div class="d-flex justify-content-end">
                    <span class="text-black mt-auto mb-auto mr-auto" style="font-weight: 400; font-size: 16px;">№ {{item.id}}</span>
                    
                    <span class="text-black align-self-end">
                        <b-badge v-if="item.status == 1" variant="outline-primary">{{$t("orders.statuses.diagnostics")}}</b-badge>
                        <b-badge v-else-if="item.status == 2" variant="danger">{{$t("orders.statuses.faulty")}}</b-badge>
                        <b-badge v-else-if="item.status == 3" variant="outline-warning">{{$t("orders.statuses.work")}}</b-badge>
                        <b-badge v-else-if="item.status == 4" variant="success">{{$t("orders.statuses.completed")}}</b-badge>
                    </span>
                </div>
                <div class="d-flex justify-content-between" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">
                        <span class="text-muted text-uppercase">{{$t('orders.table.device')}}</span>
                    
                    <span class="text-black align-self-end">
                        {{item.miner.machine.name}}
                    </span>
                </div>
                <div class="d-flex justify-content-between" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">
                    <span class="text-muted text-uppercase">{{$t('orders.table.order-date')}}</span>
                    
                    <span class="text-black align-self-end">
                        {{item.date}} <span class="text-muted">{{item.time}}</span>
                    </span>
                </div>
                <div class="d-flex justify-content-between" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">
                    <span class="text-muted text-uppercase">{{$t('orders.table.paid')}}</span>
                    
                    <span class="text-black align-self-end">
                        <span v-if="item.payment && item.payment.received > 0">{{(item.payment.summ).formatMoney(0, '','')}} <span class="text-muted">$</span></span>
                        <span v-else>0 <span class="text-muted">$</span></span>
                    </span>
                </div>
                <div class="d-flex justify-content-between" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">
                    <span class="text-muted text-uppercase">{{$t('orders.table.status')}}</span>
                    
                    <span class="text-black align-self-end">
                        <span v-if="!item.payment && item.status !== 4">{{$t("orders.statuses.payment.expect-conclusion")}}</span>
                        <span v-else-if="item.payment && item.payment.done === 1" >{{$t("orders.statuses.payment.paid")}}</span>
                        <span v-else-if="item.status == 4">{{$t("orders.statuses.payment.conclusion-ready")}}</span>
                        <span v-else>{{$t("orders.statuses.payment.awaiting-payment")}}</span>
                    </span>
                </div>
                <div class=" mt-auto mb-auto d-flex align-self-end" style="border-radius: 18px; margin-left: 12px; margin-right: -8px; padding: 8px 0px;">
                    <span style="text-decoration: underline;">{{$t('orders.table.more')}}</span>
                    <feather-icon :icon=" item.collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'" :class=" item.collapsed ? 'text-primary m-auto'  : 'text-muted m-auto' " size="20" />
                </div>
                
                <b-collapse v-model="item.collapsed" >
                    <div>
                        <div class="d-flex flex-column" style="margin-bottom: 4px;">
                            <span class="text-muted text-uppercase" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">{{$t('orders.table.conclusion')}}</span>
                            <span v-if="item.conclusion" class="py-1" v-html="item.conclusion"/>
                            <span v-else class="py-1">{{$t("orders.table.no-data")}}</span>
                        </div>
                        <div class="d-flex flex-column" style="margin-bottom: 4px;">
                            <span class="text-muted text-uppercase" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">{{$t('orders.table.materials')}}</span>
                            <div v-if="item.materials" class="d-flex flex-column py-1">
                                <span v-for="(material, index) in JSON.parse(item.materials)" :key="index">
                                    {{material.count}} поз. {{material.material}}
                                </span>
                            </div>
                            <span v-if="!item.materials" class="py-1">{{$t("orders.table.no-data")}}</span>
                        </div>
                        <div class="d-flex flex-column" style="margin-bottom: 4px;">
                            <span class="text-muted text-uppercase" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">{{$t('orders.table.status-history')}}</span>
                            <ul class="list-unstyled font-weight-light py-1">
                                <li v-for="(history, index) in item.history" :key="index" style="margin: 4px 0;">
                                    <span :class="index == 0 ? '' : 'text-muted'">{{history.date}} {{history.varieties.label}}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="item.payment" class="d-flex flex-column" style="margin-bottom: 4px;">
                            <span class="text-muted text-uppercase" style="border-bottom: solid 1px #EBECF2; padding: 8px 0px;">{{$t('orders.table.amount-payable')}}</span>
                            <div class="d-flex justify-content-between py-1" style="align-items: baseline;">
                                <span class="font-weight-bolder" style="font-size: 22px">{{item.payment.summ}} $</span>
                                <b-button v-if="item.payment.done !== 1 && item.status !== 4" @click="makePayment(item.payment)" class="mt-1" size="md" variant="outline-primary" pill block style="border-radius: 10px !important; font-size: 14px; max-width: 160px">{{$t('orders.pay')}}</b-button>
                            </div>
                        </div>
                    </div>
                </b-collapse>
                

            </b-card>

        </template>

        <b-modal v-model="showBtcInvoiceModal" centered hide-footer title="Оплата ремонта оборудования">
            <invoice-btc-view :invoice="select_payment"/>
        </b-modal>

    </div>
</template>

<script>
import InvoiceBtcView from '../modals/btc-invoice'

export default {
    data() {
        return {
            is_loading: false,

            current_page: 0,
            has_more_pages: false,
            last_page: 0,
            total_count: 0,

            showBtcInvoiceModal: false,
            select_payment: null,
            orders: [],

            filter: {
                status: null
            },

            statuses: [
                { value: null, display: "orders.tabs.all" },
                { value: "diagnostics", display: "orders.tabs.diagnostics" },
                { value: "faulty", display: "orders.tabs.faulty" },
                { value: "work", display: "orders.tabs.work" },
                { value: "complete", display: "orders.tabs.completed" }
            ],

            counts: {
                complete: 0,
                diagnostics: 0,
                faulty: 0,
                work: 0,
                total: 0
            },

            fields: [{key:'id', label: '№'}, {key:'date', label: 'Дата заявки'}, {key:'miner', label: 'Устройства'}, {key:'received', label: 'Оплачено'}, {key:'state', label: 'Статус'}, {key: 'payment_state', label: ''}],
            fields_details: [{key: "isActive", label: 'is Active'}, {key: "age" , label: 'Возраст'}],
        }
    },
    methods: {
        get() {
            this.is_loading = true;
            this.$request.get("orders.list", {status: this.filter.status})
            .then( rsp => {
                this.orders = rsp.list;
                this.counts = rsp.counts;
                this.is_loading = false;
            });
        },

        toggleCollapse(item) {
                
            if( item.collapsed === null || typeof(item.collapsed) === "undefined" ) {
                this.$set( item, 'collapsed', true );
            } else {
                this.$set( item, 'collapsed', !item.collapsed );
            }
                                
        },

        makePayment( payment ) {
            this.select_payment = payment;            

            // if( payment.payment.type === 'card' ) {
            //     window.open(`${process.env.VUE_APP_BASE_URL}payment-make-p2p/${this.P2P.invoice.identificator}`, '_blank');
            // } else {
                this.showBtcInvoiceModal = true;
            // }
            
        }
            
    },
    mounted() {
        this.get();
    },
    components: {
        InvoiceBtcView
    }

}
</script>

<style>

</style>