<template>
        <div>
            <div >
                <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('contracts.modal.text-transfer-btc')}}</p>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;"> {{$t('contracts.modal.payment-based-public-offer')}} </p>
            </div>
            
            <div class="data-form">
                <b-form-group>
                    <div class="d-flex justify-content-between">
                        <div style="flex-grow: 1;">
                            <span class="text-muted" style="font-size: 12px;">{{$t('contracts.modal.payment-amount')}}</span>
                            <p class="m-0">{{ (invoice.btc - invoice.unconfirmed - invoice.received).decimalBtcMoney() }}</p>
                        </div>
                        <div class="mt-auto mb-auto">
                            <p class="m-0 text-muted">BTC</p>
                        </div>
                    </div>
                </b-form-group>
            </div>

            <div class="d-flex">

                <div style="margin-right: 26px;">
                    <b-form-group class="form-group-with-label">
                        <div class="d-flex justify-content-between"> 
                            <div ref="container" class="wordwrap">
                                <span class="text-muted" style="font-size: 12px;">{{$t('contracts.modal.payment-address')}}</span>
                                <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ invoice.identificator }}</p>
                            </div>
                            <div 
                                v-ripple 
                                @click="copyBitcoinAddress"
                                style="margin: auto 12px; margin-right: 0;">
                                <feather-icon 
                                    icon="CopyIcon" 
                                    size="20" 
                                    class="text-muted cursor-pointer" 
                                />
                            </div>
                        </div>
                    </b-form-group>
                </div>

                <div>
                    <qrcode :value="`${invoice.identificator}`" :options="{ width: 80, margin: 0 }"></qrcode>
                </div>

            </div>
            
            <div>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('contracts.modal.payment-processing')}}</p>
            </div>
            
            <b-row style="margin-top: 12px;">
                <b-col>
                    <b-button :to="{ name: 'user-wallet', query: { act: 'output', to: invoice.identificator, amount: (invoice.btc - invoice.unconfirmed - invoice.received).decimalBtcMoney() } }" replace size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>{{$t('contracts.modal.pay-from-wallet')}}</b-button>
                </b-col>
            </b-row>

        </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    props: {
        invoice: {
            type: [Object],
            required: true,
        }
    },
    methods: {
        copyBitcoinAddress( args ) {    
            let container = this.$refs.container;
            this.$copyText(this.invoice.identificator, container);
            
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t('toasts.titles.copy'),
                    text: this.$t('toasts.text.btc-address'),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                }
            });  
        },
    }

}
</script>

<style>

</style>