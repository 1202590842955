<template>
   <div>

        <p style="font-weight: 600; font-size: 20px; margin-bottom: 16px;">{{$t('orders.title')}}</p>
        <b-card no-body>
            <b-card-header>

                    <b-col cols="12" class="d-flex justify-content-between">
                        
                        <b-button-group>
                            <template v-for="(status, index) in statuses">
                                    <b-button  @click="setStatus(status.value)" :variant="filter.status == status.value ? 'active' : 'inactive'" size="sm" style="height: 36px;">
                                        <div class="d-flex flex-row" style="align-items: center;">
                                            {{ $t(status.display) }}
                                            <div class="order-counter">
                                                <span v-if="status.value">
                                                    {{counts[status.value]}}
                                                </span>
                                                <span v-else>
                                                    {{counts.total}}
                                                </span>
                                            </div>
                                        </div>  
                                    </b-button>
                                <div v-if="index < statuses.length - 1" class="delimiter"></div>
                            </template>
                        </b-button-group> 

                    </b-col>
                
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='7' :rows='5'>
            
            </b-skeleton-table>

            <b-card-body v-else-if='orders.length == 0' class='d-flex flex-column pb-5'>
                <div class="d-flex flex-column justify-content-center">
                    <div class="nodata-icon ml-auto mr-auto"></div>
                    <p class="text-muted mt-1 ml-auto mr-auto text-center">
                        {{$t('tables.no-data')}}
                    </p>
                </div>
            </b-card-body>

            <b-table v-else :items="orders" :fields="fields" responsive="sm" class="t-order">
  
                <template #cell(id)="row">
                    <feather-icon @click="row.toggleDetails" class="text-muted" icon="ChevronRightIcon" size="16" :style="row.detailsShowing ? 'transform: rotate(-90deg) scaleX(-1);' : ''"/>
                    {{row.item.id}}
                </template>

                <template #cell(state)="row">
                    <b-badge v-if="row.item.status == 1" variant="outline-primary">{{$t("orders.statuses.diagnostics")}}</b-badge>
                    <b-badge v-else-if="row.item.status == 2" variant="danger">{{$t("orders.statuses.faulty")}}</b-badge>
                    <b-badge v-else-if="row.item.status == 3" variant="outline-warning">{{$t("orders.statuses.work")}}</b-badge>
                    <b-badge v-else-if="row.item.status == 4" variant="success">{{$t("orders.statuses.completed")}}</b-badge>
                </template>

                <template #cell(payment_state)="row">
                    <b-badge v-if="!row.item.payment && row.item.status !== 4" variant="outline-gray">{{$t("orders.statuses.payment.expect-conclusion")}}</b-badge>
                    <b-badge v-else-if="row.item.payment && row.item.payment.done === 1" variant="outline-gray">{{$t("orders.statuses.payment.paid")}}</b-badge>
                    <b-badge v-else-if="row.item.status == 4" variant="outline-gray">{{$t("orders.statuses.payment.conclusion-ready")}}</b-badge>
                    <b-badge v-else variant="outline-gray">{{$t("orders.statuses.payment.awaiting-payment")}}</b-badge>
                </template>

                <template #cell(date)="row">
                    {{row.item.date}} <span class="text-muted">{{row.item.time}}</span>
                </template>

                <template #cell(received)="row">
                    <span v-if="row.item.payment && row.item.payment.received > 0">{{(row.item.payment.summ).formatMoney(0, '','')}} $</span>
                    <span v-else>0 $</span>
                </template>

                <template #cell(miner)="row">
                    {{row.item.miner.machine.name}}
                </template>

                <template #row-details="row">
                    <b-table-simple class="font-small-3 text-black" responsive>
                        <b-thead>
                            <b-tr>
                                <b-th style="padding-left: 20px; width: 25%">{{$t("orders.table.conclusion")}}</b-th>
                                <b-th style="width: 25%">{{$t("orders.table.materials")}}</b-th>
                                <b-th style="width: 25%">{{$t("orders.table.status-history")}}</b-th>
                                <b-th style="width: 25%">{{$t("orders.table.amount-payable")}}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td v-if="row.item.conclusion" class="text-black" style="padding-left: 20px">
                                    <span v-html="row.item.conclusion"/>
                                </b-td>
                                <b-td v-else class="text-black" style="padding-left: 20px">{{$t("orders.table.no-data")}}</b-td>

                                <b-td v-if="row.item.materials" class="text-black">
                                    <span v-for="(item, index) in JSON.parse(row.item.materials)" :key="index">
                                        {{item.count}} поз. {{item.material}}
                                    </span>
                                </b-td>
                                <b-td v-else class="text-black">{{$t("orders.table.no-data")}}</b-td>

                                <b-td class="text-black">
                                    <ul class="list-unstyled font-weight-light">
                                        <li v-for="(history, index) in row.item.history" :key="index" style="margin: 4px 0;">
                                            <span :class="index == 0 ? '' : 'text-muted'">{{history.date}} {{history.varieties.label}}</span>
                                        </li>
                                    </ul>
                                </b-td>
                                <b-td v-if="row.item.payment" class="text-black">
                                    <span class="font-weight-bolder" style="font-size: 22px">{{row.item.payment.summ}} $</span>

                                    <b-button v-if="row.item.payment.done !== 1 && row.item.status !== 4" @click="makePayment(row.item.payment)" class="mt-1" size="md" variant="outline-primary" pill block style="border-radius: 10px !important; font-size: 14px; max-width: 160px">{{$t('orders.pay')}}</b-button>
                                </b-td>
                                <b-td v-else class="text-black">
                                    -
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </template>
            </b-table>
        </b-card>

        <b-modal v-model="showBtcInvoiceModal" centered hide-footer :title="$t('orders.paying-equipment-repairs')">
            <invoice-btc-view :invoice="select_payment"/>
        </b-modal>

    </div>
</template>

<script>
import InvoiceBtcView from '../modals/btc-invoice'

export default {
    data() {
        return {
            is_loading: false,

            current_page: 0,
            has_more_pages: false,
            last_page: 0,
            total_count: 0,

            showBtcInvoiceModal: false,
            select_payment: null,
            orders: [],
            filter: {
                status: null
            },

            statuses: [
                { value: null, display: "orders.tabs.all" },
                { value: "diagnostics", display: "orders.tabs.diagnostics" },
                { value: "faulty", display: "orders.tabs.faulty" },
                { value: "work", display: "orders.tabs.work" },
                { value: "complete", display: "orders.tabs.completed" }
            ],

            counts: {
                complete: 0,
                diagnostics: 0,
                faulty: 0,
                work: 0,
                total: 0
            },

            fields: [{key:'id', label: '№'}, {key:'date', label: this.$t('orders.table.order-date')}, {key:'miner', label: this.$t('orders.table.devices')}, {key:'received', label: this.$t('orders.table.paid')}, {key:'state', label: this.$t('orders.table.status')}, {key: 'payment_state', label: ''}],
        }
    },
    methods: {
        get() {
            this.is_loading = true;
            this.$request.get("orders.list", {status: this.filter.status})
            .then( rsp => {
                this.orders = rsp.list;
                this.counts = rsp.counts;
                this.is_loading = false;
            });
        },

        setStatus( value ) {
            this.filter.status = value;

            this.get();
        },

        toggleCollapse(item) {
                
            if( item.collapsed === null || typeof(item.collapsed) === "undefined" ) {
                this.$set( item, 'collapsed', true );
            } else {
                this.$set( item, 'collapsed', !item.collapsed );
            }
                            
        },

        makePayment( payment ) {
            this.select_payment = payment;            

            // if( payment.payment.type === 'card' ) {
            //     window.open(`${process.env.VUE_APP_BASE_URL}payment-make-p2p/${this.P2P.invoice.identificator}`, '_blank');
            // } else {
                this.showBtcInvoiceModal = true;
            // }
            
        }

    },
    mounted() {
       this.get();
    },
    components: {
        InvoiceBtcView
    }
}
</script>

<style lang="scss">
    .t-order {
        td {
            color: #141C40;
            &:first-child {
                padding-left: 8px;
            }
        }
    }
    .order-counter {
        background: #9CA2B1;
        border-radius: 30px;
        color: #FFFFFF;
        font-size: 9px;
        padding: 2px 4px;
        margin-left: 6px;
    }
    .b-table-details > td {       
        padding: 0px !important;
    }
</style>